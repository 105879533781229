/* eslint-disable jsx-a11y/anchor-is-valid */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useAuth } from '../../modules/auth'
import { handleGetRequest, handlePatchRequest, handlePostRequest } from '../../services'
import { ICampaign, IRecruiter } from './CampaignTable'
import CampaignEditModal from './modal/CampaignEditModal'
import CampaignEditRecruiterModal from './modal/CampaignEditRecruiterModal'
import CampaignRecruiterDeletionModal from './modal/CampaignRecruiterDeletionModal'
import CampaignRecruiterUploadModal from './modal/CampaignRecruiterUploadModal'

export default function CampaignSingle() {
  const { setLoading } = useContext(LoadingContext)
  const [campaign, setCampaign] = useState<ICampaign>()
  const { id } = useParams()
  const intl = useIntl()
  const [showUploadRecruitmentModal, setShowUploadRecruitmentModal] = useState<boolean>(false)
  const [showEditCampaignModal, setShowEditCampaignModal] = useState<boolean>(false)
  const [showEditCampaignRecruiterModal, setShowEditCampaignRecruiterModal] =
    useState<boolean>(false)
  const [showDeleteRecruiterModal, setShowDeleteRecruiterModal] = useState<boolean>(false)
  const [potentialDeleteRecruiter, setPotentialDeleteRecruiter] = useState<string | undefined>()
  const { currentUser } = useAuth()
  const [selectedRecruiters, setSelectedRecruiters] = useState<IRecruiter[]>([])

  const [selectedEditRecruiter, setSelectedEditRecruiter] = useState<IRecruiter | undefined>()

  const getCampaignDetails = async () => {
    const { data } = await handleGetRequest(`/campaign/single/${id}`)(setLoading)
    if (!data) return
    const campaignData = (data.recruiters || []).filter((r) => r.name !== null)
    setCampaign({ ...data, recruiters: campaignData })
  }

  const getTotalRecruitedUsers = () => {
    if (campaign && campaign.recruiters) {
      return campaign.recruiters.reduce(
        (acc, recruiter) => acc + recruiter.recruited_users.filter((r) => !!r.user_id).length,
        0
      )
    }
    return 0
  }

  const getTotalAmountFromRecruitedUsers = (recruiter: IRecruiter) => {
    if (campaign) {
      return recruiter.recruited_users
        .filter((r) => !!r.user_id)
        .reduce((acc, recruiter) => acc + recruiter.total_amount, 0)
    }
    return 0
  }

  const getLevel = (campaign: ICampaign) => {
    if (campaign.goal_id) {
      return 'goal'
    }
    if (campaign.organisation_sports_category_id) {
      return 'unit'
    }
    return 'organisation'
  }

  useEffect(() => {
    getCampaignDetails()
  }, [])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'campaign.details' })}</h3>

        <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <Link to='/org/campaign/' className='btn btn-primary btn-md bg-navy_blue'>
              {intl.formatMessage({ id: 'campaign.overview' })}
            </Link>
          </div>
          <div className='me-4'>
            <button
              type='button'
              className='btn  btn-primary btn-md me-2 bg-navy_blue'
              style={{ height: '46px', borderRadius: '8px' }}
              onClick={() => {
                setShowEditCampaignModal(true)
              }}
            >
              {intl.formatMessage({ id: 'edit.campaign' })}
            </button>
          </div>

          {/* end::Menu */}
        </div>
      </div>
      <div className='card mb-3'>
        <div className=''>
          <div className='modal-body px-10 py-8'>
            <div className='d-flex justify-content-between g-0 gap-0'>
              <h3 className='fw-bolder my-2'>{campaign?.title || ''}</h3>
              {/* begin::Col */}
              <div className='d-flex gap-5 justify-content-between'>
                <div className='d-flex'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-40px me-3'>
                    <div className='d-flex align-items-center bg-white bg-opacity-50'>
                      <i className='fa-duotone  fa-users fs-1' />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div
                      className='fs-14 text-dark fw-bolder lh-1'
                      style={{ color: '#000000', fontSize: '14px' }}
                    >
                      Nye støttespillere
                    </div>
                    <div
                      className='fw-bold mt-1'
                      style={{
                        whiteSpace: 'nowrap',
                        color: '#000000',
                        marginTop: '5px',
                        fontSize: '14px',
                      }}
                    >
                      {getTotalRecruitedUsers()}
                    </div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}
            </div>
            <div className=' fontsizechfslogtime d-flex align-items-center text-gray-600  me-5 my-2 presentation '>
              <CKEditor
                editor={ClassicEditor}
                data={campaign?.short_description ? campaign.short_description : ''}
                id='displayEditor'
                disabled
                config={{ toolbar: [] }}
                // set height for editor
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column' style={{ maxWidth: '50%' }}>
            <span className='card-label fw-bold fs-3 mb-1'>Deltagere</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              Her kan du opprette og administrere deltagere til kampanjen. Deltagere kan være
              enkeltpersoner eller lag. Hver deltager får en unik kode som de kan bruke til å
              invitere andre til å støtte kampanjen, samt en URL som kan deles. Disse URL-ene
              opprettes under fanen “Flyere”. Du kan velge deltagere ved knappene til venstre, og
              sende QR-koden deres ved å bruke knappen til høyre.
            </span>
          </h3>
          <div className='d-flex flex-wrap my-2'>
            <div className='me-4'>
              <button
                onClick={() => {
                  setShowUploadRecruitmentModal(true)
                }}
                className='btn btn-lg btn-primary authbgcolor'
              >
                Legg til deltagere
              </button>
            </div>
            <div className='me-4'>
              <Link
                to={`/marketing/studio/campaign/${id}`}
                className='btn btn-lg btn-primary authbgcolor'
              >
                Rediger QR-kode-flyer
              </Link>
            </div>
            <div className='me-4'>
              <button
                onClick={async () => {
                  if (selectedRecruiters.length > 0) {
                    await handlePostRequest(
                      `/organisation_user/send-campaign-email`,
                      {
                        campaignId: campaign?._id,
                        recruiter_short_user_ids: selectedRecruiters.map((r) => r.short_user_id),
                      },
                      undefined,
                      false
                    )(setLoading)
                    setLoading(false)
                  }
                }}
                disabled={!selectedRecruiters.length}
                className='btn btn-lg btn-primary authbgcolor'
              >
                Send til deltagere
              </button>
            </div>
            {/* end::Menu */}
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th className='ps-2 rounded-start text-uppercase'>
                    <Form.Check
                      type='checkbox'
                      checked={
                        selectedRecruiters.length === campaign?.recruiters?.length &&
                        selectedRecruiters.length > 0
                      }
                      onChange={() => {
                        if (selectedRecruiters.length === campaign?.recruiters?.length) {
                          setSelectedRecruiters([])
                        } else {
                          setSelectedRecruiters(campaign?.recruiters || [])
                        }
                      }}
                    />
                  </th>
                  <th className='ps-5 text-uppercase'>Navn</th>
                  <th className='text-uppercase'>Kode</th>
                  <th className='text-uppercase'>E-post</th>
                  <th className='text-uppercase'>Nye støttespillere</th>
                  <th className='text-uppercase'>Motatt støtte</th>
                  <th className=' text-end rounded-end px-4 text-uppercase' />
                </tr>
              </thead>
              <tbody>
                {campaign?.recruiters?.length! > 0 ? (
                  campaign!.recruiters.map((tbData) => (
                    <tr key={tbData?.short_user_id}>
                      <td className='ps-2 rounded-start text-uppercase'>
                        <Form.Check
                          type='checkbox'
                          checked={selectedRecruiters.some(
                            (r) => r.short_user_id === tbData?.short_user_id
                          )}
                          onChange={() => {
                            if (
                              selectedRecruiters.some(
                                (r) => r.short_user_id === tbData?.short_user_id
                              )
                            ) {
                              setSelectedRecruiters((prevSelected) =>
                                prevSelected.filter(
                                  (r) => r.short_user_id !== tbData?.short_user_id
                                )
                              )
                            } else {
                              setSelectedRecruiters((prevSelected) => [...prevSelected, tbData])
                            }
                          }}
                        />
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='mx-2 text-dark fw-bolder text-hover-primary d-block fs-6'>
                            {tbData?.name}
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {tbData?.short_user_id}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {tbData?.email}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {tbData?.recruited_users.filter((r) => !!r.user_id).length || 0}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {getTotalAmountFromRecruitedUsers(tbData)}
                        </a>
                      </td>
                      <td style={{ float: 'right', display: 'flex', gap: '5px' }}>
                        <div className='d-flex flex-shrink-0'>
                          <a
                            onClick={() => {
                              setSelectedEditRecruiter(tbData)
                              setShowEditCampaignRecruiterModal(true)
                            }}
                            title='Rediger'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg'
                          >
                            <i className='fa-duotone fa-pencil' />
                          </a>
                        </div>
                        <div className='d-flex flex-shrink-0'>
                          <a
                            onClick={() => {
                              if (campaign?.organisation_id) {
                                const url = `https://org.stotte.no/campaigncard/${tbData?.short_user_id}/${campaign.organisation_id.toString()}/${campaign.flyer?.header}/${campaign.flyer?.subheader}`
                                window.open(url, '_blank')
                              }
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg'
                            title='Vis QR-kode'
                          >
                            <i className='fa-duotone fa-eye' />
                          </a>
                        </div>
                        <div className='d-flex flex-shrink-0'>
                          <a
                            onClick={() => {
                              setPotentialDeleteRecruiter(tbData?.short_user_id)
                              setShowDeleteRecruiterModal(true)
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg'
                            title='Slett deltager'
                          >
                            <i className='fa-duotone fa-trash' />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'nothing.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CampaignEditModal
        initialValues={() => {
          return {
            name: campaign?.title || '',
            description: campaign?.short_description || '',
            start_date: campaign?.start_date || new Date(),
            end_date: campaign?.end_date || new Date(),
            level: campaign ? getLevel(campaign) : 'organisation',
            unit: campaign?.organisation_sports_category_id || '',
            goal: campaign?.goal_id || '',
          }
        }}
        setShowModal={setShowEditCampaignModal}
        showModal={showEditCampaignModal}
        onClose={() => {}}
        submitCallback={async (modalData) => {
          setLoading(true)
          const data = {
            organisation_id: currentUser?.user?.organisation_id?._id,
            organisation_sports_category_id: modalData.unit === '' ? undefined : modalData.unit,
            goal_id: modalData.goal === '' ? undefined : modalData.goal,
            status: 'active',
            title: modalData.name,
            short_description: modalData.description,
            start_date: modalData.start_date,
            end_date: modalData.end_date,
          }
          if (id) {
            await handlePatchRequest(`/campaign/${id}`, data)(setLoading)
            await getCampaignDetails()
          }
          setLoading(false)
          setShowEditCampaignModal(false)
        }}
      />
      <CampaignRecruiterDeletionModal
        handleDelete={async () => {
          if (potentialDeleteRecruiter) {
            await handlePostRequest(`/campaign/remove_user/`, {
              campaign_id: id,
              short_user_id: potentialDeleteRecruiter,
            })(setLoading)
            await getCampaignDetails()
            setPotentialDeleteRecruiter(undefined)
          }
          setShowDeleteRecruiterModal(false)
        }}
        setShowModalConfirm={setShowDeleteRecruiterModal}
        showModalConfirm={showDeleteRecruiterModal}
      />
      <CampaignRecruiterUploadModal
        handleUpload={async (recruiters, closeDialog) => {
          if (recruiters.length) {
            const { data } = await handleGetRequest(`/campaign/single/${id}`)(setLoading)
            const campaignData = data.recruiters.filter((r) => r.name !== null)
            // Check existing recruiters and add only new ones
            const newRecruiters = recruiters.filter(
              (r) => !campaignData.some((c) => c.email === r.email && c.name === r.name)
            )
            await handlePostRequest(`/campaign/add_users/`, {
              campaign_id: id,
              recruiters: JSON.stringify(newRecruiters),
            })(setLoading)
            await getCampaignDetails()
          }
          if (closeDialog) setShowUploadRecruitmentModal(false)
        }}
        setShowModalConfirm={setShowUploadRecruitmentModal}
        showModalConfirm={showUploadRecruitmentModal}
      />
      <CampaignEditRecruiterModal
        initialValues={{
          email: selectedEditRecruiter?.email || '',
          name: selectedEditRecruiter?.name || '',
        }}
        setShowModal={setShowEditCampaignRecruiterModal}
        showModal={showEditCampaignRecruiterModal}
        submitCallback={async (modalData) => {
          if (selectedEditRecruiter) {
            await handlePostRequest(`/campaign/update_user`, {
              campaign_id: id,
              short_user_id: selectedEditRecruiter.short_user_id,
              email: modalData.email,
              name: modalData.name,
            })(setLoading)
            await getCampaignDetails()
          }
          setShowEditCampaignRecruiterModal(false)
          setSelectedEditRecruiter(undefined)
        }}
      />
    </>
  )
}
