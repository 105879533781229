/* eslint-disable prefer-const */
import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import * as Yup from 'yup'
import LoadingContext from '../../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../../context'
import { useAuth } from '../../../modules/auth'
import { handleGetRequest } from '../../../services'
import { ICampaignProps } from './creation/CampaignCreationModal'

interface CampaignCreationModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  submitCallback: (values: ICampaignProps) => void
  onClose: () => void
  initialValues: () => ICampaignProps
}

const CampaignEditModal: React.FC<CampaignCreationModalProps> = ({
  showModal,
  setShowModal,
  initialValues,
  submitCallback,
  onClose,
}) => {
  const campaignSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
    level: Yup.string(),
    unit: Yup.string(),
    goal: Yup.string(),
  })
  const [loading] = React.useState(false)

  const [level, setLevel] = useState('organisation')
  const { companyofPortal } = useMainContext()
  const { setLoading } = useContext(LoadingContext)
  const [goals, setGoals] = React.useState([])
  const { currentUser } = useAuth()
  const isOnSportLevel = () => !!companyofPortal?.company_id

  const getGoalsByOrg = async () => {
    // eslint-disable-next-line no-extra-boolean-cast
    const sportId = isOnSportLevel()
      ? [companyofPortal?.company_id]
      : currentUser?.user?.sports_list?.map((item: any) => item._id)
    const params = {
      organisationId: currentUser?.user?.organisation_id?._id,
      sportId,
      limit: 1000,
      page: 1,
    }
    const res = await handleGetRequest('/goal/by_organisation', {
      params,
    })(setLoading)

    setGoals(res.data)
  }
  useEffect(() => {
    getGoalsByOrg()
  }, [companyofPortal.company_id, currentUser?.user?.organisation_id._id])

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' keyboard={false}>
      <div className='modal-body'>
        <div>
          <Formik
            initialValues={initialValues()}
            enableReinitialize
            validationSchema={campaignSchema}
            onSubmit={(values) => {
              submitCallback(values)
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div>
                  <h3 className='fw-bolder my-2'>'Rediger kampanje' </h3>
                </div>
                <div className='row mb-3 mt-7'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Navn:</label>
                  <div className='col-lg-9 fv-row'>
                    <Field
                      style={{ borderRadius: '8px' }}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-lg form-control-solid inputClass',
                        {
                          'is-invalid': touched.name && errors.name,
                        }
                      )}
                      placeholder='Angi tittel på kampanje'
                    />
                  </div>
                </div>
                <div className='row mb-3 mt-7'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Nivå:</label>
                  <div className='col-lg-9 fv-row'>
                    <Field
                      style={{ borderRadius: '8px', borderColor: '1px solid #C6E0EC' }}
                      as='select'
                      name='level'
                      className={clsx('form-select form-select-lg form-select-solid', {
                        'is-invalid': touched.level && errors.level,
                      })}
                      onChange={(e: any) => {
                        values.level = e.target.value
                        setLevel(e.target.value)
                      }}
                    >
                      <option value='organisation'>Organisasjon</option>
                      <option value='unit'>Enhet</option>
                      <option value='goal'>Formål</option>
                    </Field>
                  </div>
                </div>

                {(level === 'unit' || values.level === 'unit') && (
                  <div className='row mb-3 mt-7'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6' />
                    <div className='col-lg-9 fv-row'>
                      <Field
                        style={{ borderRadius: '8px', borderColor: '1px solid #C6E0EC' }}
                        as='select'
                        name='unit'
                        className={clsx('form-select form-select-lg form-select-solid', {
                          'is-invalid': touched.unit && errors.unit,
                        })}
                      >
                        <option value={undefined} />
                        {currentUser?.user?.sports_list.map((unit: any) => (
                          <option value={unit._id} key={unit._id}>
                            {unit.sports_category_name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                )}
                {level === 'goal' && (
                  <div className='row mb-3 mt-7'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6' />
                    <div className='col-lg-9 fv-row'>
                      <Field
                        style={{ borderRadius: '8px', borderColor: '1px solid #C6E0EC' }}
                        as='select'
                        name='goal'
                        className={clsx('form-select form-select-lg form-select-solid', {
                          'is-invalid': touched.goal && errors.goal,
                        })}
                      >
                        <option value={undefined} />
                        {goals?.map((goal: any) => (
                          <option value={goal._id} key={goal._id}>
                            {goal.title}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                )}

                <div className='row mb-3 mt-7'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Beskrivelse:</label>
                  <div className='col-lg-9 fv-row'>
                    <Field
                      style={{ borderRadius: '8px' }}
                      type='text'
                      name='description'
                      className={clsx(
                        'form-control form-control-lg form-control-solid inputClass',
                        {
                          'is-invalid': touched.description && errors.description,
                        }
                      )}
                      placeholder='Kort beskrivelse av kampanjen'
                    />
                  </div>
                </div>

                <div className=' d-flex justify-content-between mt-2'>
                  <button
                    type='button'
                    className='btn btn-light me-3'
                    style={{ color: '#3F4254', marginLeft: '-4px' }}
                    onClick={() => {
                      onClose()
                      setShowModal(false)
                    }}
                  >
                    Avbryt
                  </button>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary authbgcolor'
                    disabled={loading}
                  >
                    {!loading && <span className='indicator-label'>Lagre</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Vennligst vent...
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CampaignEditModal)
