import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'
import MarketingModal from './MarketingModal'

const MarketingVideos: React.FC = () => {
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const [PageSize] = useState(10)
  const [currentPage] = useState(1)
  const intl = useIntl()
  const { setLoading } = useContext(LoadingContext)

  const [show, setShow] = React.useState(false)
  const [modalData, setModalData] = React.useState({})
  const [goals, setGoals] = useState<any>()
  const [orgNo, setorgNO] = useState<any>(null)

  const [orgVideoDetails, setorgVideoDetails] = useState<any>({
    video: {
      deleted: false,
      _id: '64fb47d042cacc15c590b7c5',
      id: 2,
      first_name: 'Aalesunds Svømme Og Livredningsklubb',
      landing_page_preview_url: 'https://hei.stotte.no/video-mangler',
      landing_page_url: 'https://stotte.no/spre-budskapet',
      api_data_club: 'Aalesunds Svømme Og Livredningsklubb',
      api_data_org_name: 'Aalesunds Svømme Og Livredningsklubb',
      api_data_organisation_number: 990615233,
    },
    video_details: null,
  })

  const getOrgDetails = async () => {
    const response = await handleGetRequest(
      `/organisation/${currentUser?.user?.organisation_id?._id}`
    )(setLoading)
    if (response?.data) {
      setorgNO(response?.data?.organisation_number)
      const orgNo = response?.data?.organisation_number
      const { data } = await handleGetRequest(
        `/organisation/get_org_video_by_org_no?orgNo=${orgNo}`
      )(setLoading)
      if (data?.video) {
        setorgVideoDetails(data)
      }
    }
  }

  useEffect(() => {
    getOrgDetails()
  }, [modalData])

  const handleClick = (data: {}) => {
    setShow(true)
    setModalData(data)
  }

  useEffect(() => {
    const getOrgGoals = async () => {
      const params = {
        limit: PageSize,
        page: currentPage,
        organisationId: currentUser?.user?.organisation_id?._id,
        sportId: companyofPortal?.company_id,
      }
      const { data } = await handleGetRequest('/goal/by_organisation', { params })(setLoading)
      setGoals(data)
    }
    getOrgGoals()
  }, [PageSize, currentPage, currentUser, companyofPortal])

  return (
    <>
      {/* <MarketingHeader /> */}
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'share.video.and.goal' })}</h3>

        <div className='d-flex flex-wrap my-2'>
          <Link to='/marketing' style={{ color: 'white' }} className='btn btn-lg btn-orange mx-2'>
            {intl.formatMessage({ id: 'weekly.overview' })}
          </Link>
          {/* <button className='btn btn-sm btn-orange'>Ukentlig oversikt</button> */}
          {/* <FilterOptions /> */}
        </div>
      </div>

      <div className={`card `}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {' '}
              {intl.formatMessage({ id: 'share.video' })}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {intl.formatMessage({ id: 'simple.short.and.personal.videos.you.can.share' })}
            </span>
          </h3>
          <div className='card-toolbar' />
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th
                    className='ps-4 rounded-start'
                    style={{
                      width: '70%',
                    }}
                  >
                    {intl.formatMessage({ id: 'video' })}
                  </th>
                  {/* <th> VARIGHET</th> */}
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td className='d-flex align-items-center'>
                    <img
                      style={{
                        border: '1px solid #C6E0EC',
                        borderRadius: '8px',
                        objectFit: 'contain',
                        padding: '15px',
                      }}
                      src='/media/icons/youtube.svg'
                      alt='video'
                      className='h-60px w-90px'
                    />
                    <div className='ms-4 '>
                      <h5>{intl.formatMessage({ id: 'video' })} 1</h5>
                      <p className='text-gray-400'>
                        {' '}
                        {intl.formatMessage({ id: 'from.club.to.membersFrom.club.to.members' })}
                      </p>
                    </div>
                  </td>
                  {/* <td>0:20 sek</td> */}
                  <td>
                    <div className='d-flex justify-content-end'>
                      <button
                        onClick={() =>
                          handleClick({
                            input2: orgVideoDetails?.video?.landing_page_preview_url,
                            cardType: 'card10',
                            mainTitle: `${intl.formatMessage({ id: 'share.video' })}`,
                            input1: `${intl.formatMessage({ id: 'copy.the.link.and.share.it.in.the.channels' })}`,
                          })
                        }
                        className='btn btn-sm btn-green px-10'
                      >
                        {intl.formatMessage({ id: 'del' })}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <div className='card mt-8'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'share.goal' })}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {intl.formatMessage({ id: 'create.commitment.aroun.your.club.and.theirs' })}
            </span>
          </h3>
          <div className='card-toolbar' />
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th
                    className='ps-4 rounded-start'
                    style={{
                      width: '70%',
                    }}
                  >
                    {intl.formatMessage({ id: 'source' })}
                  </th>
                  {/* <th> VISSINGER</th> */}
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>

              <tbody>
                {goals?.length > 0 ? (
                  goals?.map((tbData: any) => (
                    <tr key={tbData?._id}>
                      <td className='d-flex align-items-center'>
                        <img
                          src={toAbsoluteUrl('/media/misc/Header-1.jpg')}
                          alt='video'
                          className='h-60px w-90px'
                          style={{
                            objectFit: 'cover',
                            borderRadius: '8px',
                            border: '1px solid #C6E0EC',
                          }}
                        />
                        <div className='ms-4 videotables'>
                          <h5>{tbData?.title}</h5>
                          <div className='fontsizechfslogtime text-gray-400 me-5 my-2'>
                            {tbData?.short_description
                              .substring(0, 15)
                              .replace(/(<([^>]+)>)/gi, '')}
                            ...
                          </div>
                          {/* <CKEditor
                        editor={ ClassicEditor }
                        data={tbData?.short_description.substring(0, 60) + '...'}
                        id="displayEditor"
                        disabled={true}
                        config={ { toolbar: []}}
                    
                       /> */}
                          {/* <p className='text-gray-400'>{tbData?.short_description.substring(0, 100) + '...'}</p> */}
                        </div>
                      </td>
                      {/* <td>0:20 sek</td> */}
                      <td>
                        <div className='d-flex justify-content-end'>
                          <button
                            onClick={() =>
                              handleClick({
                                input2: `${window.location.protocol}//${window.location.host}/parker/${orgNo}/${tbData?._id}`,
                                cardType: 'card10',
                                mainTitle: 'Del formål ',
                                input1: `Kopier lenken og del den i de kanalene du vanligvis kommuniserer med dine medlemmer.`,
                              })
                            }
                            className='btn btn-sm btn-green px-10'
                          >
                            {intl.formatMessage({ id: 'del' })}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'nothing.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <MarketingModal
        show={show}
        modalData={modalData}
        handleClose={() => {
          setShow(false)
        }}
        title={intl.formatMessage({ id: 'send.e-mails.to.the.trainer' })}
      />
    </>
  )
}

export default MarketingVideos
