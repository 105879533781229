import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { IRecruiter } from '../CampaignTable'

const SendCampaignLinkModal = (props: {
  onHide: (recruiters: IRecruiter[]) => void
  show: boolean
  recruiters: IRecruiter[]
}) => {
  const [selectedRecruiters, setSelectedRecruiters] = useState<IRecruiter[]>([])
  const [toggleAllChecked, setToggleAllChecked] = useState(false)

  // Toggle individual recruiter
  const toggleRecruiter = (recruiter) => {
    setSelectedRecruiters((prevSelected) => {
      if (prevSelected.some((selected) => selected.email === recruiter.email)) {
        return prevSelected.filter((selected) => selected.email !== recruiter.email)
      }
      return [...prevSelected, recruiter]
    })
  }

  // Toggle all recruiters
  const toggleAll = () => {
    if (toggleAllChecked) {
      setSelectedRecruiters([])
    } else {
      setSelectedRecruiters(props.recruiters)
    }
    setToggleAllChecked(!toggleAllChecked)
  }

  return (
    <Modal show={props.show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>Send til deltagere</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className='text-muted  fw-bold'>
          Her kan du sende en lenke til deltagere med deres kode, direkte URL og link til deres
          personlige QR-kode.
        </span>
        <div className='mb-10 mt-10'>
          <Form.Check
            type='checkbox'
            label='Velg alle'
            checked={toggleAllChecked}
            onChange={toggleAll}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {props.recruiters.map((recruiter, index) => (
            <Form.Check
              key={index}
              type='checkbox'
              style={{ marginBottom: '10px', width: '50%' }}
              label={`${recruiter.name} (${recruiter.email})`}
              checked={selectedRecruiters.some((selected) => selected.email === recruiter.email)}
              onChange={() => toggleRecruiter(recruiter)}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide([])}>Avbryt</Button>
        <Button
          onClick={() => {
            console.log('Selected recruiters:', selectedRecruiters)
            props.onHide(selectedRecruiters)
          }}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SendCampaignLinkModal
