/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { useAuth } from '../../app/modules/auth'
import mainContext from './mainContext'

function MainContextState(props) {
  const roles_permisions_of_all_Roles = {}
  const { currentUser } = useAuth()
  const setLocalStorageComapny = (obj) => {
    if (!localStorage) {
      return
    }

    try {
      const lsValue = JSON.stringify(obj)
      localStorage.setItem('Selected_Org', lsValue)
    } catch (error) {
      console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
  }

  const getLocalStorageComapny = () => {
    if (!localStorage) {
      return
    }

    const lsValue = localStorage.getItem('Selected_Org')
    if (!lsValue) {
      return
    }

    try {
      const company = JSON.parse(lsValue)
      if (company) {
        // You can easily check auth_token expiration also

        // if (
        //   allCompanies?.data?.filter(
        //     (item, i) => item?.company_list?.company?._id === companyofPortal?.company_id
        //   )?.length > 0
        // ) {
        //   return company
        // } else {
        //   return {
        //     company_id: null,
        //     inti: 'NO',
        //     name: 'Select Company',
        //   }
        return company
      }
    } catch (error) {
      console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
  }

  /// /////////////    Side Bar Secondary  Controle Function and States start here  //////////////////////////
  const [brtitle, setbrtitle] = useState('')
  const [allCompanies, setAllCompanies] = useState([])
  const [brpath, setbrpath] = useState('Selected - Category')
  const [brential, setbrential] = useState('')
  const [companyofPortal, setcompanyofPortal] = useState(
    localStorage?.getItem('Selected_Org')
      ? getLocalStorageComapny()
      : {
          company_id: null,
          inti: 'NO',
          name: 'Select Category',
        }
  )

  const [comment_task_props, setcomment_task_props] = useState({
    task_id: '',
    comment: '',
    comment_id: '',
  })

  const [taskdoc_props, settaskdoc_props] = useState({
    id: '',
    url: '',
    title: '',
    task_id: '',
  })

  const setCustomberdCrum = (brential = '', brtitle = '', brpath = '') => {
    if (brential) {
      setbrential(brential)
    }
    if (brtitle) {
      setbrtitle(brtitle)
    }

    if (brpath) {
      setbrpath(brpath)
    }
  }

  const setCompanyonPortal = (company_id, inti, name) => {
    // setCustomberdCrum(inti, name, 'Selected - Category')
    setcompanyofPortal({
      company_id,
      inti,
      name,
    })
    setLocalStorageComapny({
      company_id,
      inti,
      name,
    })
  }
  const [foundCompanyPref, setfoundCompanyPref] = useState(false)
  const [successMessageSupport, setsuccessMessageSupport] = useState('')

  const [objectCompanyPref, setobjectCompanyPref] = useState({
    member: [
      {
        first_name: '',
        last_name: '',
        roles: '',
        email: '',
        superuser: false,
      },
    ],
    shareholder: [
      {
        first_name: '',
        last_name: '',
        email: '',
        org_number: '',
        org_name: '',
        share: '',
        share_perc: '',
        share_price: '',
      },
    ],
    name: '',
    address: '',
    org_number: '',
    establish: '',
    company_type: '',
    no_emp: '',
    nac_industry: '',
    total_share: '',
  })

  const [refetchNoti, setrefetchNoti] = useState(false)

  const [topic_id_uid, settopic_id_uid] = useState()
  const [topic_id, settopic_id] = useState()
  const [topicTitle, settopicTitle] = useState()
  const [topicDescription, settopicDescription] = useState()
  const [activeTopic, setactiveTopic] = useState(true)
  const [organisationLogo, setOrganisationLogo] = useState('')
  const isSameUser = (user_id = '') => {
    const logedUser = user_id || currentUser?.user?._id

    if (logedUser === currentUser?.user?._id) {
      return true
    }
    return false
  }

  const checkPermissionsForUserDependedAndCompany = (
    module = '',
    permission_key = '',
    company_id = '',
    user_id = ''
  ) => {
    const companyId = company_id || companyofPortal?.company_id
    const logedUser = user_id || ''
    const founRole = currentUser?.user?.company_list?.find((item) => item.company_id === companyId)
    const roleArray = founRole === undefined ? [] : founRole.roles
    const output = Object.entries(roles_permisions_of_all_Roles).map(([key, value]) => ({
      key,
      value,
    }))
    if (permission_key && module) {
      const myArrayFiltered = output.filter((el) =>
        roleArray.some((f) => f === el.key && el.value[module][permission_key] === true)
      )

      if (logedUser === currentUser?.user?._id || myArrayFiltered?.length > 0) {
        return true
      }
      return false
    }
    // console.log('provide right permission_key and module')
    return true
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <mainContext.Provider
      value={{
        setCustomberdCrum,
        setcompanyofPortal,
        setCompanyonPortal,
        brential,
        brpath,
        brtitle,
        companyofPortal,
        comment_task_props,
        setcomment_task_props,
        taskdoc_props,
        settaskdoc_props,
        foundCompanyPref,
        setfoundCompanyPref,
        objectCompanyPref,
        setobjectCompanyPref,
        refetchNoti,
        setrefetchNoti,
        checkPermissionsForUserDependedAndCompany,
        isSameUser,
        topic_id_uid,
        topic_id,
        topicTitle,
        topicDescription,
        settopic_id_uid,
        settopic_id,
        settopicTitle,
        settopicDescription,
        activeTopic,
        setactiveTopic,
        allCompanies,
        setAllCompanies,
        successMessageSupport,
        setsuccessMessageSupport,
        organisationLogo,
        setOrganisationLogo,
      }}
    >
      {props.children}
    </mainContext.Provider>
  )
}
export default MainContextState
