/* eslint-disable no-script-url */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import CustomPagination from '../../../CustomPagination'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleDeleteRequest, handleGetRequest, handlePostRequest } from '../../services'
import CreateGoalModal from './_modals/GoalCreationModal'

type Props = {
  className: string
}

const GoalTable: React.FC<Props> = ({ className }) => {
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const { setLoading } = useContext(LoadingContext)
  const navigate = useNavigate()
  const [editorData, setEditorData] = useState('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
  const [deleteUserData, setDeleteUserData] = useState<any>({})
  const [goals, setGoals] = useState<any>()
  const intl = useIntl()
  const [PageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const initialValues = {
    title: '',
    short_description: '',
    target_amount: '',
    start_date: '',
    due_date: '',
  }
  console.log(currentUser)
  const isOnSportLevel = () => !!companyofPortal?.company_id
  const isOnValidSport = () => {
    const isOnMainLevel = !companyofPortal?.company_id
    const sports_list = currentUser?.user?.sports_list
    if (
      !isOnMainLevel &&
      sports_list?.length > 0 &&
      sports_list.find((item: any) => item._id === companyofPortal?.company_id)?.status !==
        'disabled'
    ) {
      return true
    }
    return false
  }

  const getGoalsByOrg = async () => {
    // eslint-disable-next-line no-extra-boolean-cast
    const sportId = isOnSportLevel()
      ? [companyofPortal?.company_id]
      : currentUser?.user?.sports_list?.map((item: any) => item._id)
    const params = {
      organisationId: currentUser?.user?.organisation_id?._id,
      sportId,
      limit: PageSize,
      page: currentPage,
    }
    const res = await handleGetRequest('/goal/by_organisation', {
      params,
    })(setLoading)

    setGoals(res)
  }

  const submitStep = async (values: any) => {
    const body: any = {
      organisation_id: currentUser?.user?.organisation_id?._id,
      organisation_sports_category_id: companyofPortal?.company_id,
      title: values?.title,
      short_description: values?.short_description,
      target_amount: values?.target_amount,
      start_date: values?.start_date,
      due_date: values?.due_date ? values?.due_date : null,
      banner_image: values?.banner_image,
    }
    values.start_date = moment(values?.start_date).format('YYYY-MM-DD').toString()
    if (values?.due_date) {
      values.due_date = moment(values?.due_date).format('YYYY-MM-DD').toString()
    }

    const { success } = await handlePostRequest('/goal', body)(setLoading)

    if (success) {
      getGoalsByOrg()
    }

    setShowModal(false)
  }

  const handleDelete = async () => {
    const { success } = await handleDeleteRequest(`/goal/${deleteUserData?._id}`)(setLoading)
    if (success) getGoalsByOrg()
    setShowModal(false)
    setShowModalConfirm(false)
  }

  function GetStatus(status = 'active') {
    const badgeClass = {
      active: 'badge-light-warning',
      completed: 'badge-light-success',
      paused: 'badge-light-info',
      canceled: 'badge-light-danger',
    }[status]

    return (
      <span className={`badge status-button badge-sm ${badgeClass}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    )
  }

  const formalAddFunction = () => {
    // setShowModal(true)
    navigate('/formalAdd/')
    setEditorData('')
  }

  useEffect(() => {
    getGoalsByOrg()
  }, [PageSize, currentPage, companyofPortal.company_id, currentUser?.user?.organisation_id._id])

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'overview.goal' })}
            </span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Lorem ipsium</span> */}
          </h3>
          {isOnValidSport() && (
            <div className='card-toolbar'>
              <div
                onClick={() => formalAddFunction()}
                className='btn btn-lg btn-primary authbgcolor'
              >
                {intl.formatMessage({ id: 'create.goal' })}
              </div>
            </div>
          )}
          {!isOnValidSport() && (
            <div className='card-toolbar'>
              For å opprette formål må du velge enhet i menyen til venstre.
            </div>
          )}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th className='ps-4 rounded-start'>{intl.formatMessage({ id: 'goal' })}</th>
                  {!isOnValidSport() && <th>{intl.formatMessage({ id: 'unit' })}</th>}
                  <th className=''>{intl.formatMessage({ id: 'support.players' })}</th>
                  <th className=''>{intl.formatMessage({ id: 'startup' })}</th>
                  <th>{intl.formatMessage({ id: 'support.received' })}</th>
                  <th>{intl.formatMessage({ id: 'status' })}</th>
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {goals?.data?.length > 0 ? (
                  goals?.data?.map((tbData: any) => (
                    <tr key={tbData?._id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <Link
                            to={`/formalSingle/${tbData?._id}`}
                            className='symbol symbol-circle left-text '
                          >
                            <i className=' fa-light fa-bullseye-pointer' />
                          </Link>
                          <Link
                            to={`/formalSingle/${tbData?._id}`}
                            className='mx-2 text-dark fw-bolder text-hover-primary d-block fs-6 left-text'
                          >
                            {tbData?.title}
                          </Link>
                        </div>
                      </td>
                      {!isOnValidSport() && (
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold d-block mb-1 fs-6'
                            style={{ cursor: 'auto' }}
                          >
                            {tbData?.organisationsports?.[0].sports_category_name}
                          </a>
                        </td>
                      )}
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {tbData?.total_active_supporter_player}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {moment(tbData?.start_date).format('DD.MM.YYYY')}
                        </a>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bold d-block mb-1 fs-6 text-cursor'>
                          {tbData?.support_total_amount}
                        </a>
                      </td>
                      <td className=''>{tbData?.status && GetStatus(tbData?.status)}</td>
                      <td style={{ float: 'right' }}>
                        <div className='d-flex flex-shrink-0'>
                          <Link
                            to={`/formalEdit/${tbData?._id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg me-1'
                          >
                            <i className='fa-duotone fa-pencil' />
                          </Link>
                          {currentUser?.user?.user_type === 'admin' &&
                            tbData?.total_active_supporter_player < 1 && (
                              <a
                                onClick={() => {
                                  setDeleteUserData(tbData)
                                  setShowModalConfirm(true)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg'
                              >
                                <i className='fa-duotone fa-trash' />
                              </a>
                            )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'nothing.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <CustomPagination
                className='pagination-bar justify-content-md-end'
                currentPage={currentPage}
                totalCount={goals ? goals?.pagination?.totalRecords : 0}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <CreateGoalModal
        showModal={showModal}
        setShowModal={setShowModal}
        submitStep={submitStep}
        initialValues={initialValues}
        editorData={editorData}
        setEditorData={setEditorData}
      />

      <Modal
        show={showModalConfirm}
        onHide={() => setShowModalConfirm(false)}
        size='lg'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'> {intl.formatMessage({ id: 'del.goal' })}</h5>
          </Modal.Title>
        </Modal.Header>
        <div className='modal-body'>
          {intl.formatMessage({ id: 'del.goal.confirm' })}
          <br />
          <br />
          <br />
          <button
            type='button'
            className='btn  avbryt me-3'
            onClick={() => {
              setShowModalConfirm(false)
            }}
          >
            {intl.formatMessage({ id: 'Cancel' })}
          </button>
          <button
            type='button'
            style={{ float: 'right' }}
            onClick={() => {
              handleDelete()
            }}
            className='btn slett '
          >
            {intl.formatMessage({ id: 'yes.delete' })}
          </button>
        </div>
      </Modal>
    </>
  )
}

export { GoalTable }
